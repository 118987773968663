export const HOME_SPORTS = [
  {
    title: 'Thể Thao',
    image: 'assets/images/home/home-sport.png',
    link: '/the-thao'
  },
  {
    title: 'Live Casino',
    image: 'assets/images/home/home-casino.png',
    link: '/livecasino/all'
  },
  {
    title: 'Quay Số',
    image: 'assets/images/home/home-quay-so.png',
    link: '/game/quay-so'
  },
  {
    title: 'table games',
    image: 'assets/images/home/home-table-game.png',
    link: '/game/table-game',
    jackpot: 'jackpotIngame'
  },
  {
    title: 'nổ hũ',
    image: 'assets/images/home/home-nohu.png',
    link: '/game/no-hu',
    jackpot: 'jackpotNohu'
  },
  {
    title: 'game bài',
    image: 'assets/images/home/home-game-bai.png',
    link: '/game/game-bai'
  },
  {
    title: 'lô đề',
    image: 'assets/images/home/home-lode.png',
    link: '/game/lo-de'
  },
  {
    title: 'Slots',
    image: 'assets/images/home/home-slots.png',
    link: '/game/slots'
  },
  {
    title: 'bắn cá',
    image: 'assets/images/home/home-ban-ca.png',
    link: '/game/ban-ca',
    jackpot: 'jackpotFishing'
  },
  {
    title: 'game nhanh',
    image: 'assets/images/home/home-game-nhanh.png',
    link: '/game/game-nhanh'
  }
]
